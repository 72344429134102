import React from 'react';
import Navbar from './Navbar';

export default function Terms() {
  return (
    <>
        <Navbar />
        <div className="bg-base-100 text-base-content p-8">
        <h1 className="text-2xl font-bold mb-4">Terms & Services</h1>
        <p className="text-sm text-gray-500 mb-8">Last Updated: November 1, 2024</p>

        <div className="space-y-6">
            <section>
            <h2 className="text-xl font-semibold mb-2">1. General Information</h2>
            <p>
                Affirmation Generator is an online platform where users can enter their personal goals for
                manifestation, and our AI generates an audio track of affirmations with soothing background music.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">2. Ownership of Generated Content</h2>
            <p>
                When purchasing a package, users have the right to create personalized affirmation audio tracks.
                Ownership of the generated audio rests with the user, who may download and use it freely.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">3. Refund Policy</h2>
            <p>
                If you are not satisfied with your purchase, you may request a full refund by contacting{' '}
                <a href="mailto:support@affirmation-generator.com" className="text-primary hover:underline">
                support@affirmation-generator.com
                </a>{' '}
                and stating the reason for your dissatisfaction.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">4. Data Collection</h2>
            <p>
                We collect personal information such as email addresses and payment details to process transactions
                and manage user accounts. We also collect non-personal data through web cookies to improve our
                services. For more information, please review our{' '}
                <a
                href="https://affirmation-generator.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
                >
                Privacy Policy
                </a>.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">5. Changes to the Terms</h2>
            <p>
                We reserve the right to modify these Terms & Services. Any updates will be communicated to users by
                email.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">6. Governing Law</h2>
            <p>These Terms & Services are governed by the laws of Australia.</p>
            </section>

            <footer className="pt-6 border-t">
            <p className="text-sm">
                If you have any questions or need further clarification, please reach out to us at{' '}
                <a href="mailto:support@affirmation-generator.com" className="text-primary hover:underline">
                support@affirmation-generator.com
                </a>.
            </p>
            <p className="mt-2">Thank you for using Affirmation Generator!</p>
            </footer>
        </div>
        </div>
    </>
  );
}
