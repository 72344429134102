import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import { useAuth } from './context/AuthContext';

const GenerateAffirmation = ({ setAudioFiles, setFreeGenerationUsed }) => {
  const [goal, setGoal] = useState('');
  const { isSubscribed } = useAuth();
  const [loading, setLoading] = useState(false); // New state for loading spinner

  const generateAffirmation = async () => {
    setLoading(true); // Start loading when generation starts
    try {
      console.log("Attempting to generate affirmation")
      const { data: { user }, error } = await supabase.auth.getUser();
  
      if (error || !user) {
        console.error('Error fetching user:', error);
        setLoading(false);
        return;
      }
  
      // Check if the user has already used their free generation
      const { data: usageData, error: usageError } = await supabase
        .from('generation_usage')
        .select('user_id')
        .eq('user_id', user.id)
        .single();
  
      console.log('data:', usageData);

      if (usageError && usageError.code !== 'PGRST404' && usageError.code !== 'PGRST116') {
        console.error('Error checking free generation usage:', usageError);
        setLoading(false);
        return;
      }
  
      if (usageData && !isSubscribed) {
        alert("You've used your free generation. Please subscribe for more.");
        setLoading(false);
        return;
      }
  
      // Proceed with generating the affirmation
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          goal,
          user_id: user.id,
        }),
      });
  
      const data = await response.json();
      const filename = data.filename;
  
      // Generate a signed URL for the new file
      const { data: { signedUrl }, error: urlError } = await supabase
        .storage
        .from('affirmations-audio')
        .createSignedUrl(filename, 86400);
  
      if (urlError) {
        console.error('Error generating signed URL:', urlError);
        setLoading(false);
        return;
      }
  
      // Insert user_id into generation_usage to mark that free generation has been used
      await supabase
        .from('generation_usage')
        .insert([{ user_id: user.id }]);
      
      setFreeGenerationUsed(true);
  
      setAudioFiles((prevFiles) => [...prevFiles, { filename, signedUrl, goal: data.goal }]);
  
    } catch (error) {
      console.error('Error generating affirmation:', error);
    } finally {
      setLoading(false); // Stop loading when generation is complete
    }
  };

  return (
    <div className="card bg-base-100 shadow-lg p-6 mb-6">
      <h2 className="text-2xl font-bold mb-4">
        {isSubscribed ? "Generate Your Affirmation" : "Generate One Free Affirmation, On Us"}
      </h2>
      <div className="flex space-x-4">
        <input
          type="text"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
          placeholder="Enter your goal"
          className="input input-bordered w-full"
          disabled={loading} // Disable input while loading
        />
        <button
          className={`btn btn-primary ${loading ? "btn-disabled" : ""}`}
          onClick={generateAffirmation}
          disabled={loading} // Disable button while loading
        >
          {loading ? (
            <span className="loading loading-spinner loading-xs"></span> // Daisy UI spinner
          ) : (
            "Generate"
          )}
        </button>
      </div>
    </div>
  );
};

export default GenerateAffirmation;
