import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';  // Import AuthProvider and useAuth
import LandingPage from './LandingPage';
import Dashboard from './Dashboard';
import './App.css';
import Privacy from './Privacy';
import Terms from './Terms';

const AppRoutes = () => {
  const { session, loading } = useAuth();  // Access session and loading from AuthContext
  const navigate = useNavigate();

  useEffect(() => {
    if (session) {
      // FIXME: Privacy page redirects to dashboard when logged in
      navigate('/dashboard');
    }
  }, [session]);
  if (loading) return <LandingPage />;  // Loading state while checking session

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/dashboard" element={session ? <Dashboard /> : <Navigate to="/" />} />
      <Route path='/privacy' element={<Privacy/>}/>
      <Route path='/terms' element={<Terms/>}/>
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <AppRoutes />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
