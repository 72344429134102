import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import CTAButton from './CTAButton';
import './Navbar.css';

const Navbar = () => {
  const { session, isSubscribed, loading, logout } = useAuth();

  return (
    <nav className="navbar bg-base-100 shadow-md px-4 py-2">
      <div className="flex-1">
        <Link to="/" className="text-xl font-bold flex items-center justify-center">
          <img src="affirmation-generator.png" width={70} alt="logo" />
          <span className='logo-title h-fit'>Affirmation Generator</span>
        </Link>
      </div>

      <div className="flex-none flex items-center space-x-4">
        {session ? (
          <>
            <Link to="/dashboard" className="btn btn-primary">
              Dashboard
            </Link>
            <div className="dropdown dropdown-end z-50">
              <label tabIndex={0} className="btn btn-ghost rounded-btn">
                Account
              </label>
              <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                <li><p className="user-email text-gray-700">{session.user.email}</p></li>
                <li>
                  {loading ? (
                    <p className="text-gray-500">Checking subscription...</p>
                  ) : isSubscribed ? (
                    <p className="text-green-500">Subscribed</p>
                  ) : (
                    <>
                      <p className="text-red-500">You aren't subscribed</p>
                      <button className="btn btn-primary">Subscribe to Premium</button>
                    </>
                  )}
                </li>
                <li>
                  <button className="btn btn-secondary" onClick={logout}>
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <CTAButton />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
