import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PricingSection = () => {
  
  const { session } = useAuth()
  const [userId, setUserId] = useState();

  useEffect(() => {
    setUserId(session?.user?.id)
  }, [session])

  console.log("Location:", useLocation().pathname)
  const handleCheckout = async (priceId, subscriptionType) => {
    const stripe = await stripePromise;
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/stripe/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        userId,
        subscriptionType,
      }),
    });
    const data = await response.json();
    if (data.id) {
      const { error } = await stripe.redirectToCheckout({ sessionId: data.id });
      if (error) console.error('Stripe checkout error:', error);
    }
  };

  return (
    <section className="pricing relative p-12">
      {/* Background layer with updated z-index */}
      <div className="absolute inset-0 -z-20 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]"></div>
      
      {/* Content layer */}
      <div className="max-w-7xl mx-auto text-center space-y-8 relative z-10">
        { useLocation().pathname === "/dashboard" ? (
          <>
            <h2 className="text-3xl font-bold mb-5">Want more affirmations? 🔮</h2>
            <p className="mb-5">Just one last step to get unlimited!</p>
          </>
        ) : (
           <h2 className="text-4xl font-bold">Pricing</h2>

        )}
        <div className="flex flex-wrap justify-center gap-8">
          {/* Monthly Plan - Most Popular */}
          <div className="pricing-card flex max-w-[400px] min-w-[290px] flex-1 flex-col bg-base-100 z-50">
            <div className="relative flex flex-col flex-grow rounded-lg border-2 border-primary px-6 sm:px-9 py-9">
              <div className="absolute -top-3 left-1/2 -translate-x-1/2 select-none rounded-full bg-accent px-4 py-1 text-xs font-bold text-white shadow-md">
                Most Popular
              </div>
              <h3 className="text-lg sm:text-xl font-bold font-header tracking-wide">Monthly Subscription</h3>
              <div className="flex text-xl sm:mt-9 mt-6 items-end">
                <p className="mr-1 line-through text-gray-600">$10</p>
                <p className="text-4xl sm:text-5xl mr-2 font-bold">$3</p>
                <span className="text-sm font-medium">USD</span>
              </div>
              <div className="text-left my-9 sm:my-12 flex-grow">
                <p className="flex items-center gap-2 my-3 font-medium">
                  <svg className="w-5 h-5 fill-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M424-296l282-282-56-56-226 226-114-114-56 56 170 170Z"/></svg>
                  Unlimited affirmations
                </p>
                <p className="flex items-center gap-2 my-3 font-medium">
                  <svg className="w-5 h-5 fill-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M424-296l282-282-56-56-226 226-114-114-56 56 170 170Z"/></svg>
                  Downloadable Audio Tracks
                </p>
                <p className="flex items-center gap-2 my-3 font-medium">
                  <svg className="w-5 h-5 fill-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M424-296l282-282-56-56-226 226-114-114-56 56 170 170Z"/></svg>
                  Optional daily emails with affirmations
                </p>
              </div>
              <button
                className="w-full rounded-lg bg-accent text-white py-2 text-sm font-semibold mt-4"
                onClick={() => handleCheckout(process.env.REACT_APP_MONTHLY_ID, 'monthly')}
              >
                Start Monthly Plan
              </button>
              <p className="mt-6 text-center text-sm font-medium text-gray-600">
                Getting started takes <span className="underline">less than 2 minutes.</span>
              </p>
            </div>
          </div>

          {/* Lifetime Plan */}
          <div className="pricing-card flex max-w-[400px] min-w-[290px] flex-1 flex-col bg-base-100 z-50">
            <div className="relative flex flex-col flex-grow rounded-lg border-2 border-gray-300 px-6 sm:px-9 py-9 text-secondary-content">
              <h3 className="text-lg sm:text-xl font-bold font-header tracking-wide">Lifetime Access</h3>
              <div className="flex text-xl sm:mt-9 mt-6 items-end">
                <p className="mr-1 line-through text-gray-600">$120</p>
                <p className="text-4xl sm:text-5xl mr-2 font-bold">$50</p>
                <span className="text-sm font-medium">USD</span>
              </div>
              <div className="my-9 sm:my-12 flex-grow text-left">
                <p className="flex items-center gap-2 my-3 font-medium">
                  <svg className="w-5 h-5 fill-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M424-296l282-282-56-56-226 226-114-114-56 56 170 170Z"/></svg>
                  All Monthly Plan Features
                </p>
                <p className="flex items-center gap-2 my-3 font-medium">
                  <svg className="w-5 h-5 fill-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M424-296l282-282-56-56-226 226-114-114-56 56 170 170Z"/></svg>
                  Lifetime Access to Updates
                </p>
              </div>
              <button
                className="w-full rounded-lg bg-accent text-white py-2 text-sm font-semibold mt-4"
                onClick={() => handleCheckout(process.env.REACT_APP_LIFETIME_ID, 'lifetime')}
              >
                Get Lifetime Access
              </button>
              <p className="mt-6 text-center text-sm font-medium text-gray-600">
                Invest in your goals for life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
