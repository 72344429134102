import React from 'react';
import Navbar from './Navbar';

export default function Privacy() {
  return (
    <>
        <Navbar />
        <div className="bg-base-100 text-base-content p-8">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-sm text-gray-500 mb-8">Last Updated: November 1, 2024</p>

        <div className="space-y-6">
            <section>
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p>
                Welcome to Affirmation Generator. At{' '}
                <a
                href="https://affirmation-generator.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
                >
                https://affirmation-generator.com
                </a>
                , we value your privacy and are committed to protecting your personal information. This Privacy Policy
                outlines how we collect, use, and safeguard your information when you use our services.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">2. Data Collection</h2>
            <p>
                We collect non-personal data, including web cookies, to enhance your experience and facilitate order
                processing. These cookies help us improve website functionality and provide a personalized experience.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">3. Use of Collected Data</h2>
            <p>
                The data collected is used exclusively for order processing purposes. We do not share your data with any
                third parties.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">4. Children's Privacy</h2>
            <p>
                Affirmation Generator does not knowingly collect or store any data from children under the age of 13. If
                we become aware of any such data, it will be promptly deleted.
            </p>
            </section>

            <section>
            <h2 className="text-xl font-semibold mb-2">5. Updates to this Privacy Policy</h2>
            <p>
                We may update this Privacy Policy periodically. Any changes will be communicated to users via email.
            </p>
            </section>

            <footer className="pt-6 border-t">
            <p className="text-sm">
                If you have any questions or concerns regarding this Privacy Policy, please contact us at{' '}
                <a href="mailto:support@affirmation-generator.com" className="text-primary hover:underline">
                support@affirmation-generator.com
                </a>.
            </p>
            <p className="mt-2">Thank you for using Affirmation Generator!</p>
            </footer>
        </div>
        </div>
    </>
  );
}
