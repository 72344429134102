
import React from 'react';
import './FeaturesSection.css';  // Assuming you have a CSS file for styling

const FeaturesSection = () => {
  return (
    <section className="features-section relative p-12">
      <div className='absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]'>
        <div className="max-w-7xl mx-auto text-center space-y-8">
          <h2 className="text-4xl font-bold">Why Choose Affirmation-Generator.com?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            
            {/* Card 1 */}
            <div className="card shadow-lg p-6 bg-base-100 rounded-md">
              <div className="icon bg-primary rounded-full w-12 h-12 flex items-center justify-center mb-4 mx-auto">
                {/* Use a suitable icon */}
                <i className="fas fa-brain text-white text-2xl"></i>
              </div>
              <h3 className="text-xl font-bold mb-2">Personalized Affirmation Generation</h3>
              <p>No more one-size-fits-all affirmations. Input your specific goals, and let our AI create affirmations that speak directly to you.</p>
            </div>
            
            {/* Card 2 */}
            <div className="card shadow-lg p-6 bg-base-100 rounded-md">
              <div className="icon bg-primary rounded-full w-12 h-12 flex items-center justify-center mb-4 mx-auto">
                {/* Use a suitable icon */}
                <i className="fas fa-music text-white text-2xl"></i>
              </div>
              <h3 className="text-xl font-bold mb-2">Customizable Listening Experience</h3>
              <p>Choose from a variety of soothing background sounds—whether it's calming music, nature sounds, or ambient tones—to enhance your affirmation sessions.</p>
            </div>
            
            {/* Card 3 */}
            <div className="card shadow-lg p-6 bg-base-100 rounded-md">
              <div className="icon bg-primary rounded-full w-12 h-12 flex items-center justify-center mb-4 mx-auto">
                {/* Use a suitable icon */}
                <i className="fas fa-clock text-white text-2xl"></i>
              </div>
              <h3 className="text-xl font-bold mb-2">Flexible Timing</h3>
              <p>Set the interval between affirmations to suit your preference, allowing you to absorb each message fully.</p>
            </div>
            
          </div>
        </div>

      </div>
    </section>
  );
};

export default FeaturesSection;
