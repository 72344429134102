import React, { useRef, useState, useEffect } from 'react';

const AudioLibrary = ({ audioFiles }) => {
  const [waveCurrentTime, setWaveCurrentTime] = useState([]);
  const [volumeControls, setVolumeControls] = useState([]);

  const audioRefs = useRef(audioFiles.map(() => ({
    main: null,
    wave: null,
  })));

  // Update state when audioFiles changes
  useEffect(() => {
    audioRefs.current = audioFiles.map((_, index) => audioRefs.current[index] || { main: null, wave: null });

    setWaveCurrentTime((prevTimes) => [
      ...prevTimes,
      ...Array(audioFiles.length - prevTimes.length).fill(0),
    ]);
    setVolumeControls((prevControls) => [
      ...prevControls,
      ...Array(audioFiles.length - prevControls.length).fill({
        isVisible: false,
        volume: 0.5,
      }),
    ]);
  }, [audioFiles]);

  const handlePlay = (index) => {
    const waveAudio = audioRefs.current[index].wave;
    const mainAudio = audioRefs.current[index].main;

    if (waveAudio) {
      waveAudio.currentTime = waveCurrentTime[index];
      waveAudio.volume = volumeControls[index].volume * mainAudio.volume;
      waveAudio.muted = mainAudio.muted;
      waveAudio.play();
    }

    if (mainAudio) {
      mainAudio.play();
    }
  };

  const handlePause = (index) => {
    const waveAudio = audioRefs.current[index].wave;
    const mainAudio = audioRefs.current[index].main;

    if (waveAudio) {
      setWaveCurrentTime((prev) => {
        const updatedTimes = [...prev];
        updatedTimes[index] = waveAudio.currentTime;
        return updatedTimes;
      });
      waveAudio.pause();
    }

    if (mainAudio) {
      mainAudio.pause();
    }
  };

  const handleTimeUpdate = (index) => {
    const mainAudio = audioRefs.current[index].main;
    const waveAudio = audioRefs.current[index].wave;

    if (mainAudio && waveAudio) {
      waveAudio.currentTime = mainAudio.currentTime;
    }
  };

  const toggleVolumeControl = (index) => {
    setVolumeControls((prev) =>
      prev.map((control, i) =>
        i === index
          ? { ...control, isVisible: !control.isVisible }
          : control
      )
    );
  };

  const handleVolumeChange = (index, value) => {
    setVolumeControls((prev) => {
      const updatedControls = [...prev];
      updatedControls[index].volume = value;
      return updatedControls;
    });

    const waveAudio = audioRefs.current[index].wave;
    const mainAudio = audioRefs.current[index].main;
    if (waveAudio && mainAudio) {
      waveAudio.volume = value * mainAudio.volume;
    }
  };

  const handleMainAudioVolumeChange = (index) => {
    const waveAudio = audioRefs.current[index].wave;
    const mainAudio = audioRefs.current[index].main;

    if (waveAudio && mainAudio) {
      waveAudio.volume = volumeControls[index].volume * mainAudio.volume;
      waveAudio.muted = mainAudio.muted;
    }
  };

  return (
    <div className="audioLibrary">
      {audioFiles.length > 0 && (
        <>
          <h3 className="text-2xl font-bold mb-4">Your Audio Files</h3>
          <ul className="flex flex-col-reverse">
            {audioFiles.map((file, index) => (
              <li key={index} className="card bg-base-100 shadow-lg p-4 mb-5">
                <p className="font-bold mb-2">{file.goal}</p>
                

                <audio
                  controls
                  className="w-full"
                  onPlay={() => handlePlay(index)}
                  onPause={() => handlePause(index)}
                  onTimeUpdate={() => handleTimeUpdate(index)}
                  onVolumeChange={() => handleMainAudioVolumeChange(index)}
                  ref={(el) => {
                    if (audioRefs.current[index]) audioRefs.current[index].main = el;
                  }}
                >
                  <source src={file.signedUrl} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>

                <audio
                  ref={(el) => {
                    if (audioRefs.current[index]) audioRefs.current[index].wave = el;
                  }}
                  loop
                  style={{ display: 'none' }}
                >
                  <source src="/music2.mp3" type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>

                <div className="flex items-center gap-2 mt-2">
                  <label
                    className="cursor-pointer"
                    onClick={() => toggleVolumeControl(index)}
                  >
                    🎶
                  </label>

                  {volumeControls[index]?.isVisible && (
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={volumeControls[index].volume}
                      onChange={(e) => handleVolumeChange(index, parseFloat(e.target.value))}
                      className="ml-2"
                    />
                  )}
                </div>

              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AudioLibrary;
