import React from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { useAuth } from './context/AuthContext';

const CTAButton = ({text}) => {
  const { session } = useAuth();
  const navigate = useNavigate();

  const handleButtonClick = async () => {
    if (session) {
      navigate('/dashboard');
    } else {
      const { error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
      if (error) console.error('Error logging in:', error.message);
    }
  };

  return (
    <button className="btn btn-primary" onClick={handleButtonClick}>
      { text ? (
        text 
      ) : (
        session ? 'Go to Dashboard' : 'Get Started'
      )
      }
    </button>
  );
};

export default CTAButton;
