import React from 'react';
import Navbar from './Navbar'; 
import CTAButton from './CTAButton';
import PricingSection from './PricingSection';
import FeaturesSection from './FeaturesSection';
import './LandingPage.css';

const LandingPage = ({ session }) => {
  return (
    <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]"><div className="absolute bottom-0 left-0 right-0 top-0 bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)]">
    
      <div className="landing-page min-h-screen">
        <Navbar session={session} />

        {/* Hero Section */}
        <section className="hero min-h-[60vh] py-12">
          <div className="hero-content flex flex-col lg:flex-row items-center justify-between max-w-7xl mx-auto">
            <div className="text-section flex flex-col items-start space-y-5 lg:w-1/2">
              <h1 className="text-5xl font-bold">Personalized Affirmations Tailored to Your Journey</h1>
              <p className="text-xl">
                Transform your manifestations with AI-powered affirmations crafted specifically for your goals.
              </p>
              <CTAButton text="Get started free" />
            </div>

            {/* Demo Section */}
            <div className="demo-section lg:w-1/2 mt-8 lg:mt-0 flex flex-col items-center space-y-4">
              <div className="chat chat-start">
                <div className="chat-image avatar">
                  <div className="w-10 rounded-full">
                    <img src="/ds.jpeg" alt="User Avatar" />
                  </div>
                </div>
                <div className="chat-bubble">I want to be promoted to Assistant Regional Manager.</div>
              </div>
              <div className="chat chat-end">
                <img src="/curved-arrow.svg" width={40} style={{marginBottom: '20px'}} alt="Arrow pointing to affirmation audio" />
                {/* <div className="chat-bubble"> */}
                  <audio controls className="demoAudio">
                    <source src="https://sghgswiyfebjwpsqvrcg.supabase.co/storage/v1/object/sign/affirmations-audio/1730527661051.mp3?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJhZmZpcm1hdGlvbnMtYXVkaW8vMTczMDUyNzY2MTA1MS5tcDMiLCJpYXQiOjE3MzA1MjgxODQsImV4cCI6MTc2MjA2NDE4NH0.ceufsnv2kJqQbE3ItEOweIQVns8S6J-Q4cBMNdYkDpU&t=2024-11-02T06%3A16%3A25.143Z" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              {/* </div> */}
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how-it-works py-12 bg-base-100">
          <div className="max-w-7xl mx-auto text-center space-y-8">
            <h2 className="text-4xl font-bold">How It Works</h2>
            <div className="howItWorks mt-9 px-11 text-left">
              <p className="flex items-start gap-1.5 mt-9">
                <span className="text-4xl sm:text-5xl font-semibold tracking-tight font-mono text-primary-200 select-none opacity-60">1.</span>
                <span className="sm:text-xl text-base font-medium leading-[1.8] tracking-tight text-gray-800 inline-block">Share your aspirations</span>
              </p>
              <p className="flex items-start gap-1.5 mt-9">
                <span className="text-4xl sm:text-5xl font-semibold tracking-tight font-mono text-primary-200 select-none opacity-80">2.</span>
                <span className="sm:text-xl text-base font-medium leading-[1.8] tracking-tight text-gray-800 inline-block">Receive affirmations uniquely tailored to your goals and mindset 🙌</span>
              </p>
              <p className="flex items-start gap-1.5 mt-9">
                <span className="text-4xl sm:text-5xl font-semibold tracking-tight font-mono text-primary-200 select-none opacity-100">3.</span>
                <span className="sm:text-xl text-base font-medium leading-[1.8] tracking-tight text-gray-800 inline-block">Listen and align yourself 🧘🏻‍♀️ with the path to success.</span>
              </p>
            </div>
          </div>
        </section>

        {/* Why Choose Affirmation-Generator.com */}

        <FeaturesSection />

        {/* Testimonials Section */}
        <section className="testimonials py-12 bg-base-100">
          <div className="max-w-7xl mx-auto text-center space-y-8">
            <div className="flex flex-col items-center">
              <p className="text-xl p-10 pb-0 italic">"I used to listen to affirmations on YouTube but this has transformed my manifestation experience. These affirmations make my visualizations so much more vivid and effective."</p>
              <p className="mt-4 font-bold pb-10">— Abbey P.</p>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <PricingSection userId={session?.user?.id} />
        
        <div className="faq p-10 flex flex-col items-center bg-base-100">
          <h1 className='text-4xl font-bold m-5'>FAQ</h1>
          <div className="join join-vertical px-12 bg-transparent  max-w-7xl">
            <div className="collapse collapse-arrow join-item bg-base-100 border-base-300 border ">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                How does Affirmation-Generator work?
              </div>
              <div className="collapse-content">
                <p>
                  You simply enter the goal you want to manifest and we pass it to ChatGPT to generate appropriate affirmations for you, 
                  <br /><br />We then vocalise that with ElevenLabs and finally bring it to you, adding calming music and natural sounds 
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-base-100 border-base-300 border">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                Is my data private?
              </div>
              <div className="collapse-content">
                <p>
                  We only store your email, the goals you have generated and the affirmation audio in a database.
                  <br />
                  The data cannot be accessed by anyone else.
                  <br />
                  <br />
                  If you want to delete your account and all related data, email support@affirmation-generator.com
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-base-100 border-base-300 border">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                Can I use the generator offline?
              </div>
              <div className="collapse-content">
                <p>
                  Unfortunately you aren't able to generate any affirmations while offline.
                  <br />
                  You can however download the audio that you've generated and listen to that on the go at any time!
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-base-100 border-base-300 border">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                What is your refund policy?
              </div>
              <div className="collapse-content">
                <p>
                  If you aren't happy with Affirmation Generator, send us an email with what went wrong and we'll happily refund you! <br /> support@affirmation-generator.com
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-base-100 border-base-300 border">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                Can I give feedback/request a feature?
              </div>
              <div className="collapse-content">
                <p>
                  We would absolutely love your feedback!
                  <br />
                  Send us an email with your thoughts and ideas at support@affirmation-generator.com
                </p>
              </div>
            </div>
          </div>
        </div>


        {/* Footer CTA */}
        <section className="footer-cta relative py-12">
          
          <div className="absolute inset-0 -z-10 h-full w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)]">
          </div>

            <div className="absolute w-full mx-auto text-center space-y-6 p-12">
              <h2 className="text-4xl font-bold">Ready to elevate your manifestation journey?</h2>
              <CTAButton session={session} text="Get Started Today" />
            </div>
        </section>

        {/* Footer */}
        <footer className="footer bg-base-300 py-6">
          <div className="max-w-7xl mx-auto text-center">
            <p>© 2024 Affirmation-Generator.com. All rights reserved.</p>
            <div>
              <a href="/terms" className="link link-hover">Terms of Service</a> | 
              <a href="/privacy" className="link link-hover"> Privacy Policy</a>
            </div>
          </div>
        </footer>
      </div>
    </div></div>
  );
};

export default LandingPage;
