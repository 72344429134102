import React, { useEffect, useState} from 'react';
import Navbar from './Navbar';
import DashboardSubscribed from './DashboardSubscribed';
import DashboardNotSubscribed from './DashboardNotSubscribed';
import { useAuth } from './context/AuthContext';
import './Dashboard.css';
import { supabase } from './supabaseClient';
import PricingSection from './PricingSection';

const Dashboard = () => {
  const { session, isSubscribed, loading } = useAuth();
  const [freeGenerationUsed, setFreeGenerationUsed] = useState(false);

  useEffect(() => {
    const fetchGenerationUsage = async () => {
      console.log("Fetching Generation Usage")
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('generation_usage')
        .select('user_id')
        .eq('user_id', user.id)
        .maybeSingle(); // Use maybeSingle() instead of single()
    
      if (error && error.code !== 'PGRST404') {
        console.error('Error fetching generation usage:', error);
      } else {
        console.log("Free generation used:", !!data)
        setFreeGenerationUsed(!!data); // data will be null if no row exists
      }
    };
    
    if (!loading) {
      fetchGenerationUsage();
    }
  }, [loading]);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="min-h-screen">
      <Navbar />
      <DashboardSubscribed freeGenerationUsed={freeGenerationUsed} setFreeGenerationUsed={setFreeGenerationUsed} />
        {
          !isSubscribed && (
            <PricingSection />
          )
        }
    </div>
  );
};


export default Dashboard