import React, { useEffect, useState } from 'react';
import { supabase } from './supabaseClient';
import AudioLibrary from './AudioLibrary';
import GenerateAffirmation from './GenerateAffirmation';
import { useAuth } from './context/AuthContext';
import PricingSection from './PricingSection';

const DashboardSubscribed = ({ freeGenerationUsed, setFreeGenerationUsed }) => {
  const [audioFiles, setAudioFiles] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for skeleton
  const { isSubscribed } = useAuth();

  useEffect(() => {
    const fetchAudioFiles = async () => {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();

      const { data: files, error } = await supabase
        .from('audiofiles')
        .select('filename, goal')
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching audio files:', error);
        setLoading(false);
        return;
      }

      const signedUrls = await Promise.all(
        files.map(async (file) => {
          const { data: { signedUrl }, error: urlError } = await supabase
            .storage
            .from('affirmations-audio')
            .createSignedUrl(file.filename, 60);

          if (urlError) {
            console.error('Error generating signed URL:', urlError);
            return null;
          }

          return { filename: file.filename, goal: file.goal, signedUrl };
        })
      );

      setAudioFiles(signedUrls.filter(Boolean));
      setLoading(false); // Stop loading after fetching
    };

    fetchAudioFiles();
  }, []);

  return (
    <div className="dashboard container mx-auto py-10">
      <h2 className="text-3xl font-bold mb-5">Welcome to Your Dashboard</h2>
      {(!freeGenerationUsed || isSubscribed) && (
        <GenerateAffirmation setAudioFiles={setAudioFiles} setFreeGenerationUsed={setFreeGenerationUsed} />
      )}
      {loading ? (
        <AudioLibrarySkeleton /> // Show skeleton while loading
      ) : (
        <AudioLibrary audioFiles={audioFiles} />
      )}
    </div>
  );
};

export default DashboardSubscribed;

// Skeleton component for audio files
const AudioLibrarySkeleton = () => {
  return (
    <div className="audioLibrary">
      <h3 className="text-2xl font-bold mb-4">Your Audio Files</h3>
      <ul className="flex flex-col-reverse">
        {[...Array(3)].map((_, index) => (
          <li key={index} className="card bg-base-100 shadow-lg p-4 mb-5 animate-pulse">
            <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
            <div className="h-8 bg-gray-200 rounded w-full"></div>
          </li>
        ))}
      </ul>
    </div>
  );
};
